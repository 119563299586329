module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://editor.abandonedstroller.com/graphql","baseUrl":"editor.abandonedstroller.com","hostingWPCOM":false,"protocol":"https","useACF":false,"auth":{},"verboseOutput":false,"presets":[{}],"debug":{"preview":true,"graphql":{"showQueryVarsOnError":true,"showQueryOnError":true,"writeQueriesToDisk":true}},"production":{"allow404Images":true},"schema":{"requestConcurrency":10,"previewRequestConcurrency":2},"type":{"MediaItem":{"lazyNodes":true},"Comment":{"exclude":true}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
