// custom typefaces
import "normalize.css"
import "@fontsource/montserrat"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/900.css"
import "@fontsource/merriweather"
import "@fontsource/rock-salt"

import './src/assets/scss/style.scss'

